import { initialState } from "./initial";
import {
  ActionTypeMix,
  SET_AUTH_STATUS,
  SET_CREATING_SERVER,
  SET_GET_SERVERS_STATUS,
  SET_GET_SETTINGS_STATUS,
} from "./types";
import { IState } from "./iState";

export default (state = initialState, action: ActionTypeMix): IState => {
  switch (action.type) {
    case SET_AUTH_STATUS:
      return { ...state, authProcess: action.value.value };
    case SET_GET_SERVERS_STATUS:
      return { ...state, getServerProcess: action.value.value };
    case SET_GET_SETTINGS_STATUS:
      return { ...state, settingsLoading: action.value.value };
    case SET_CREATING_SERVER:
      return { ...state, creatingServer: action.value.value };
    default:
      return state;
  }
};
