import { Layout, Table } from "antd";
import styles from "./index.module.css";
import { ColumnsType } from "antd/es/table";
import { TRootReducer } from "../../../../app/redux/common/root";
import { useDispatch, useSelector } from "react-redux";
import { UserInfo, UserRoles, UsingPort } from "../../../../types";
import Typography from "antd/es/typography/Typography";
import { UserTag } from "../../../micro/userTag";

type Props = {
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const UsingPortsTable = ({ notify }: Props) => {
  const dispatch = useDispatch();
  const settings_state = useSelector((state: TRootReducer) => state.settings);

  const content = (user: UserInfo) => (
    <div>
      <p>{UserRoles[user.role]}</p>
    </div>
  );

  const columns: ColumnsType<UsingPort> = [
    {
      title: "Значение",
      dataIndex: "port",
      key: "port",
      render: (_, record) => (
        <>
          <Typography>{record.port}</Typography>
        </>
      ),
    },
    {
      title: "Пользователь",
      dataIndex: "user",
      key: "user",
      width: "30%",
      render: (_, record) => (
        <>
          <UserTag user={record.user} />
        </>
      ),
    },
  ];

  return (
    <>
      <Layout.Header className={styles.header}>Список используемых портов</Layout.Header>
      <Table
        style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
        dataSource={settings_state.using_ports}
        columns={columns}
      ></Table>
    </>
  );
};
