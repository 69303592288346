import {
  Button,
  Carousel,
  Flex,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  Skeleton,
  Tour,
  TourProps,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FormInput } from "../../../inputs/formInput";
import { CheckOutlined, CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { TRootReducer } from "../../../../app/redux/common/root";
import { Loader } from "../../../loader";
import { addServerThinc } from "../../../../app/redux/common/reducers/servers/thuncs";
const { Title } = Typography;

type Props = {
  setOpenModal: (value: boolean) => void;
  isOpenModal: boolean;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const AddServerModal = ({ setOpenModal, isOpenModal, notify }: Props) => {
  const ref1 = useRef(null);
  const [tourOpen, setTourOpen] = useState(false);
  const dispatch = useDispatch();
  const settings_state = useSelector((state: TRootReducer) => state.settings);
  const statuses = useSelector((state: TRootReducer) => state.status);
  const [portInput, setPortInput] = useState("");
  const [coresOnSelect, setCoresOnSelect] = useState<string[]>([]);

  const onChangePort = (value: any) => {
    setPortInput(value);
  };

  const onChangeVersion = (value: any) => {
    const tmp_ver = settings_state.versions.find((version) => {
      return version.name === value;
    });
    console.log(tmp_ver);
    if (tmp_ver) if (tmp_ver.cores) setCoresOnSelect(tmp_ver.cores);
  };

  const createServer = (data: any) => {
    const isUsing = settings_state.using_ports.find((item) => {
      return item.port === portInput;
    });
    if (isUsing || parseInt(portInput) > 25565 || parseInt(portInput) < 25530) {
      notify.setError("Порт занят!");
    } else {
      addServerThinc(data, dispatch, notify);
      setOpenModal(false);
    }
  };

  const setPortPrefix = () => {
    const isUsing = settings_state.using_ports.find((item) => {
      return item.port === portInput;
    });
    if (portInput === "") {
      return <WarningOutlined style={{ color: "yellow" }} />;
    }
    if (isUsing || parseInt(portInput) > 25565 || parseInt(portInput) < 25530) {
      return (
        <Popover title="Порт занят" content={<>Используйте другой порт</>}>
          <CloseOutlined style={{ color: "red" }} />
        </Popover>
      );
    } else {
      return <CheckOutlined style={{ color: "green" }} />;
    }
  };

  useEffect(() => {
    setTourOpen(true);
  }, []);

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  // from https://react-slick.neostack.com/docs/example/custom-arrows
  const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        ref={ref1}
        style={{ ...style, right: "-50px", display: "block" }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", left: "-50px" }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const steps: TourProps["steps"] = [
    {
      title: "Нужна загрузить сборку?",
      description: "Используй форму для загрузки своей сборки!",
      placement: "right",
      target: () => ref1.current,
    },
  ];

  return (
    <Modal
      title="Создание сервера"
      centered
      open={isOpenModal}
      footer=""
      onOk={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
    >
      {statuses.settingsLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {statuses.creatingServer ? (
            <>
              <Skeleton />
            </>
          ) : (
            <>
              <Form onFinish={createServer} style={{ marginBottom: "10px", marginInline: "10px" }}>
                <FormInput name="name" placeholder="Название" />
                <Title level={5}>
                  Порт(25530 - 25565): {setPortPrefix()}
                  <Flex vertical={false}>
                    <Form.Item
                      rules={[
                        { required: true, message: "Обязательное поле" },
                        { max: 5, min: 5, message: "Указано неверно!" },
                      ]}
                      name="port"
                      style={{ width: "100%" }}
                    >
                      <Input.OTP
                        onChange={onChangePort}
                        length={5}
                        formatter={(str) => str.toUpperCase()}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true, message: "Обязательное поле" }]}
                      name="memory"
                      style={{ width: "100%" }}
                    >
                      <Select
                        placeholder="Количество памяти в Gb"
                        options={[
                          { name: "1", value: "1" },
                          { name: "2", value: "2" },
                          { name: "3", value: "3" },
                          { name: "4", value: "4" },
                          { name: "5", value: "5" },
                        ]}
                      />
                    </Form.Item>
                  </Flex>
                </Title>
                <Flex vertical={false}>
                  <Form.Item
                    rules={[{ required: true, message: "Обязательное поле" }]}
                    name="version"
                    style={{ width: "100%" }}
                  >
                    <Select
                      placeholder="Версия"
                      onChange={onChangeVersion}
                      options={settings_state.versions?.map((value) => {
                        return { value: value.name, label: value.name };
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Обязательное поле" }]}
                    name="ver_core"
                    style={{ width: "100%" }}
                  >
                    <Select
                      placeholder="Ядро"
                      disabled={coresOnSelect.length === 0}
                      options={coresOnSelect?.map((value) => {
                        return { value: value, label: value };
                      })}
                    />
                  </Form.Item>
                </Flex>
                <Form.Item
                  rules={[{ required: true, message: "Обязательное поле" }]}
                  name="javaId"
                  style={{ width: "100%" }}
                >
                  <Select
                    placeholder="Версия Java"
                    options={settings_state.java_versions
                      .filter((el) => el.id !== -1)
                      .map((value) => {
                        return { value: value.id, label: value.name };
                      })}
                  />
                </Form.Item>
                <Button
                  key="createServerBtn"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={false}
                >
                  Создать
                </Button>
              </Form>
            </>
          )}
        </>
      )}
      <></>
    </Modal>
  );
};

/*              <Carousel arrows {...settings} style={{ paddingBottom: "10px" }} infinite={false}>
                <div></div>
              </Carousel>
              
              
              
               <Tour
            open={tourOpen}
            onClose={() => setTourOpen(false)}
            mask={false}
            steps={steps} 
          />*/
