import React from "react";
import { MinecrServer } from "../../../../../../types";
import { TerminalServer } from "../terminal";
import { GeneralSettingsMenu } from "../generalSettings";
import { ProperitesServerPage } from "../properitesMenu";
import { WorldServerManager } from "../worldManager";
import { UploadServerFiles } from "../uploadFiles";
import { StatysticPage } from "../statystic";

type Props = {
  page: number;
  serverInfo: MinecrServer;
  socket: any;
  status: string;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
  memoryUse: number;
};

export const StatsServerSwither = ({memoryUse, serverInfo, socket, status, page, notify }: Props) => {
  switch (page) {
    case 1:
      return <GeneralSettingsMenu serverInfo={serverInfo} notify={notify} />;
    case 2:
      return <ProperitesServerPage serverInfo={serverInfo} notify={notify} />;
    case 3:
      return (
        <WorldServerManager
          status={status}
          serverInfo={serverInfo}
          notify={notify}
          socket={socket}
        />
      );
    case 4:
      return <TerminalServer serverInfo={serverInfo} notify={notify} socket={socket} />;
    case 5:
      return (
        <UploadServerFiles
          files={serverInfo.mods_files}
          serverInfo={serverInfo}
          notify={notify}
          folder="mods"
        />
      );
    case 6:
      return (
        <UploadServerFiles
          files={serverInfo.plugins_files}
          serverInfo={serverInfo}
          notify={notify}
          folder="plugins"
        />
      );
      case 7 :
        return (
          <StatysticPage memoryS={memoryUse} memoryT={parseInt(serverInfo.memory)} />
        )
    default:
      return <>пусто{`${page}`}</>;
      break;
  }
};
