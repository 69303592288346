import { Dispatch } from "@reduxjs/toolkit";
import { MinecrServer, NotyfyType } from "../../../../../types";
import { setCreatingServerStatus, setGetServerStatus } from "../status/actions";
import {
  apiAddServer,
  apiCloseServerProcessFromID,
  apiDeleteFile,
  apiEditFileProperite,
  apiEditServer,
  apiGetAllServers,
  apiGetAllUserServers,
  apiGetServer,
  apiRemoveServerFromID,
  apiRemoveServerWorldFromID,
  apiSendCommandToServer,
  apiStartServerFromID,
  apiStopServerFromID,
} from "../../../../api";
import { setServer, setServers } from "./actions";
import { getAllSettingsThunc } from "../settings/thuncs";

const errror_message = (err: any, notyfy: NotyfyType) => {
  if (err.code === "ERR_NETWORK") {
    notyfy.setError("Нет связи с сервером!");
  }  
  if (err.response.data.message) {
    notyfy.setError(err.response.data.message);
  } else {
    notyfy.setError(err.message);
  }
}



export const getAllServersUserThunc = async (dispatch: Dispatch<any>, notyfy: NotyfyType) => {
  dispatch(setGetServerStatus(true));
  await apiGetAllUserServers()
    .then((res: any) => {
      dispatch(setServers(res.data));
      dispatch(setGetServerStatus(false));
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
      dispatch(setGetServerStatus(false));
    });
};

export const getAllServersThunc = async (dispatch: Dispatch<any>, notyfy: NotyfyType) => {
  dispatch(setGetServerStatus(true));
  await apiGetAllServers()
    .then((res: any) => {
      dispatch(setServers(res.data));
      dispatch(setGetServerStatus(false));
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
      dispatch(setGetServerStatus(false));
    });
};

export const addServerThinc = async (
  value: MinecrServer,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  dispatch(setCreatingServerStatus(true));
  await apiAddServer(value)
    .then(() => {
      notyfy.setSuccses(`Сервер '${value.name}' успешно создан!`);
      dispatch(setCreatingServerStatus(false));
      getAllServersUserThunc(dispatch, notyfy);
      getAllSettingsThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
      dispatch(setCreatingServerStatus(false));
    });
};

export const removeServerThinc = async (
  id: string,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  await apiRemoveServerFromID(id)
    .then((res: any) => {
      getAllServersUserThunc(dispatch, notyfy);
      getAllSettingsThunc(dispatch, notyfy);
      notyfy.setSuccses(`${res.data.message}`);
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
    });
};//apiRemoveServerWorldFromID

export const startServerThunc = async (id: string, dispatch: Dispatch<any>, notyfy: NotyfyType) => {
  await apiStartServerFromID(id)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data.message}`);
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
    });
};

export const stopServerThunc = async (id: string, dispatch: Dispatch<any>, notyfy: NotyfyType) => {
  await apiStopServerFromID(id)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data.message}`);
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
    });
};

export const sendCommandToServerThunc = async (
  id: string,
  command: any,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  await apiSendCommandToServer(id, command)
    .then(() => {})
    .catch((err: any) => {
      errror_message(err, notyfy);
    });
};

export const editServerThunc = async (
  id: string,
  value: any,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  await apiEditServer(id, value)
    .then(() => {
      getAllServersUserThunc(dispatch, notyfy);
      getAllSettingsThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
    });
};

export const closeProcessThunc = async (
  id: string,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  await apiCloseServerProcessFromID(id)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data.message}`);
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
    });
};

export const getServerFromIDThunc = async (
  id: string,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  dispatch(setGetServerStatus(true));
  await apiGetServer(id)
    .then((res: any) => {
      dispatch(setServer(res.body));
      dispatch(setGetServerStatus(false));
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
      dispatch(setGetServerStatus(false));
    });
};

export const deleteFileThunc = async (
  id: string,
  path: string,
  filename: string,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  await apiDeleteFile(id, path, filename)
    .then(() => {
      getAllServersUserThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
    });
};

export const editFileProperiteThunc = async (
  id: string,
  param: string,
  value: string,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  await apiEditFileProperite(id, param, value)
    .then(() => {
      getAllServersUserThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
    });
};

export const removeServerWorldThunc = async (
  id: string,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  await apiRemoveServerWorldFromID(id)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data.message}`);
    })
    .catch((err: any) => {
      errror_message(err, notyfy);
    });
};//apiRemoveServerWorldFromID
