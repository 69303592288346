import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";

type Props = {
  setMenuPage: (page: number) => void;
  currentPage: number
}

export const SettingsMenu = ({setMenuPage, currentPage}: Props) => {

  const setMenusPage = (event: any) => {
    setMenuPage(parseInt(event.key));
  };

  return (
    <Sider>
      <Menu defaultSelectedKeys={[`${currentPage}`]} onSelect={setMenusPage}>
        <Menu.Item key={1} >Пути</Menu.Item>
        <Menu.Item key={2}>Java Версии</Menu.Item>
        <Menu.Item key={3}>Разрешенные параметры</Menu.Item>
        <Menu.Item key={4}>Список используемых портов</Menu.Item>
      </Menu>
    </Sider>
  );
};