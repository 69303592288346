import { MinecrServer } from "../../../../../types";
import { ActionType } from "../../types/types";

export type T_SET_SERVERS = "SET_SERVERS";
export type T_SET_SERVER = "SET_SERVER";
export const SET_SERVERS: T_SET_SERVERS = "SET_SERVERS";
export const SET_SERVER: T_SET_SERVER = "SET_SERVER";

export type ActionTypeMix =
  | ActionType<T_SET_SERVERS, MinecrServer[]>
  | ActionType<T_SET_SERVER, MinecrServer>;
