import { IState } from './iState';
import { ActionTypeMix, SET_AUTH_STATUS, SET_CREATING_SERVER, SET_GET_SERVERS_STATUS, SET_GET_SETTINGS_STATUS } from './types';

export const setAuthStatus = (isInProgress: boolean): ActionTypeMix => ({
  type: SET_AUTH_STATUS,
  value: {value: isInProgress } 
});

export const setGetServerStatus = (isInProgress: boolean): ActionTypeMix => ({
    type: SET_GET_SERVERS_STATUS,
    value: { value: isInProgress}
})

export const setGetSettingsStatus = (isInProgress: boolean): ActionTypeMix => ({
  type: SET_GET_SETTINGS_STATUS,
  value: { value: isInProgress}
})

export const setCreatingServerStatus = (isInProgress: boolean): ActionTypeMix => ({
  type: SET_CREATING_SERVER,
  value: { value: isInProgress}
})

