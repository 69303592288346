import { initialState } from './initial';
import {
  ActionTypeMix,
  SET_USER
} from './types';
import { IState } from './iState';

export default (state = initialState, action: ActionTypeMix): IState => {
  switch (action.type) {
    case SET_USER:
      return action.value 
    default:
      return state;
  }
};