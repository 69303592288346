import { Gauge } from "@ant-design/charts";
import { useEffect, useState } from "react";

type Props = {
  memoryS: number;
  memoryT: number;
};

export const StatysticPage = ({ memoryS, memoryT }: Props) => {
  const [conf, setConf] = useState({
    width: 250,
    height: 250,
    autoFit: true,
    legend: true,
    data: { target: memoryS, total: memoryT, name: "score" }
  });
  
  useEffect(()=>{
    setConf({
    ...conf,
    data: { target: memoryS, total: memoryT, name: "score" }
    });
  }, [memoryS]);

  return <Gauge {...conf} />; 
};
