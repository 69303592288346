import { AllowProperite, JavaVersion, Versions } from "../../../../../types";
import { ActionType } from "../../types/types";
import { IState } from "./iState";

export type T_SET_SETTINGS = "SET_SETTINGS";
export type T_ADD_JAVA = "ADD_JAVA";
export type T_ADD_ALLOW_PROPERITE = "ADD_ALLOW_PROPERITE";
export type T_SET_VERSIONS = "SET_VERSIONS";

export const SET_SETTINGS: T_SET_SETTINGS = "SET_SETTINGS";
export const ADD_JAVA: T_ADD_JAVA = "ADD_JAVA";
export const ADD_ALLOW_PROPERITE: T_ADD_ALLOW_PROPERITE = "ADD_ALLOW_PROPERITE";
export const SET_VERSIONS: T_SET_VERSIONS = "SET_VERSIONS";

export type ActionTypeMix =
  | ActionType<T_SET_SETTINGS, IState>
  | ActionType<T_ADD_JAVA, JavaVersion>
  | ActionType<T_ADD_ALLOW_PROPERITE, AllowProperite>
  | ActionType<T_SET_VERSIONS, Versions[]>;;
