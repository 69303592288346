import { Card } from "antd";
import React from 'react';
import useDeviceDetect from "../../../../../../../../app/hooks/isMobile";

type Props = {
  data: string;
  bottomRef: any;
};

export const TerminalWindow = ({ data, bottomRef }: Props) => {
  const { isMobile } = useDeviceDetect();
  return (
    <Card title="Terminal">
      <div style={{ overflowY: "auto", flexDirection: 'column-reverse', height: isMobile ? "410px" : "493px" }}>
        {data.split("\n").map((el) => (
          <p>{el}</p>
        ))}
        <div ref={bottomRef} />
      </div>
    </Card>
  );
};
