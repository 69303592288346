import { combineReducers } from 'redux';
import auth_red from './reducers/auth/reducer';
import servers_red from './reducers/servers/reducer';
import status_red from './reducers/status/reducer';
import settings_red from './reducers/settings/reducer';

export type TRootReducer = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    user: auth_red,
    servers: servers_red,
    status: status_red,
    settings: settings_red
});

export default rootReducer;