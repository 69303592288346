export type ErrorWithMessage = {
    status: number | string;
    data: {
        message: string;
    }
}

type fileT =  {
    file: string
}

export type MinecrServer = {
    id: string;
    name: string;
    port: string;
    version: string;
    memory: string;
    status: string;
    ver_core:  string
    mods: boolean;
    plugins: boolean;
    userId: string;
    javaId: number;
    user: User;
    plugins_files: fileT[];
    mods_files: fileT[];
    error?: boolean;
  };
  
  export interface User {
    name: string;
    email: string;
    role: number;
    id: number;
    avatar: string;
    user_servers_limit: number;
}

export interface UserInfo {
    name: string;
    role: number;
    id: number;
    avatar: string;
    user_servers_limit: number;
}

export type NotyfyType = {
    setSuccses: (msg: string) => void,
    setError: (msg: string) => void
}

export type AllSettings = {
    setting: Setting | undefined,
    java_versions: JavaVersion[],
    allow_properite: AllowProperite[],
    using_ports: UsingPort[]

}

export type Setting = {
    cores_folder: string;
}

export type JavaVersion = {
    id: number,
    path: string,
    name: string
}

export type AllowProperite = {
    id: number,
    value: string,
    name: string,
    type: string
}

export type UsingPort = {
    id: number,
    port: string,
    user: UserInfo
}

export type Core = {
    core: string
}

export type Versions = {
    name: string,
    cores: string[]
}

export enum UserRoles {
    USER = 0,
    ADMIN
}

export enum ServerStatuses {
    'Выключен' = 0,
    "Загружается",
    "Активен"
}