import { Button, Form } from "antd";
import { FormInput } from "../inputs/formInput";
import { PasswordInput } from "../inputs/passwordInput";
import { registerThunc } from "../../app/redux/common/reducers/auth/thuncs";
import { useDispatch } from "react-redux";

type Prop<T> = {
    notify: {
        setSuccses: (msg: string) => void,
        setError: (msg: string) => void;
      }
};

export const RegForm = ({ notify }: Prop<string>) => {
  const dispatch = useDispatch();

  const register = async (user: any) => {
    await registerThunc(user, dispatch, notify);
    window.location.reload();
  };

  return (
    <>
      <Form onFinish={register} style={{ marginBottom: "10px" }}>
        <FormInput name="name" placeholder="Имя" />
        <FormInput name="email" placeholder="Email" />
        <PasswordInput name="password" placeholder="Пароль" />
        <PasswordInput name="confirmPassword" placeholder="Повтор пароля" />
        <Button type="primary" htmlType="submit">
          Зарегестрироваться
        </Button>
      </Form>
    </>
  );
};
