import { NotyfyType } from "../../../../../types";
import { apiCurrent, apiLogin, apiRegister } from "../../../../api";
import { setAuthStatus } from "../status/actions";
import { setUser } from "./actions";
import { Dispatch } from "@reduxjs/toolkit";

interface loginData {
  email: string;
  password: string;
}

export const logoutThunc = (dispatch: Dispatch<any>) => {
  localStorage.removeItem("token");
  dispatch(setUser({ user: undefined, isAuth: false }));
};

export const loginThunc = async (data: loginData, dispatch: Dispatch<any>, notyfy: NotyfyType) => {
  await apiLogin(data)
    .then(async (res: any) => {
      const tmp_user = res.data.user;
      await localStorage.setItem("token", res.data.token);
      dispatch(setUser({ user: tmp_user, isAuth: true }));
      notyfy.setSuccses(`Успешная авторизация: ${res.data.user.name}`);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        notyfy.setError("Нет связи с сервером!");
      } else {
        notyfy.setError(err.message);
      }
    });
};

export const registerThunc = async (
  data: loginData,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  await apiRegister(data)
    .then((res: any) => {
      dispatch(setUser({ user: res.data, isAuth: true }));
      localStorage.setItem("token", res.data.token);
      notyfy.setSuccses(`Успешная регистрация: ${res.data.name}`);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        notyfy.setError("Нет связи с сервером!");
      } else {
        notyfy.setError(err.response.data.message);
      }
    });
};

export const currentThunc = async (dispatch: Dispatch<any>, notyfy: NotyfyType) => {
  dispatch(setAuthStatus(true));
  if (localStorage.getItem("token")) {
    await apiCurrent()
      .then(async function (response: any) {
        const tmp_user = response.data;
        dispatch(setUser({ user: tmp_user, isAuth: true }));
        dispatch(setAuthStatus(false));
      })
      .catch((err: any) => {
        console.log(err);
        if (err.code === "ERR_NETWORK") {
          notyfy.setError("Нет связи с сервером!");
        } else if (err.code == 400) {
          notyfy.setError(err.response.data.message);
        } else {
          notyfy.setError(err.message);
        }
        dispatch(setAuthStatus(false));
      });
  } else {
    dispatch(setAuthStatus(false));
  }
};
