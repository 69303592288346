import { AllowProperite, JavaVersion, Versions } from "../../../../../types";
import { IState } from "./iState";
import { ADD_ALLOW_PROPERITE, ADD_JAVA, ActionTypeMix, SET_SETTINGS, SET_VERSIONS } from "./types";

export const setSettings = (storage: IState): ActionTypeMix => ({
  type: SET_SETTINGS,
  value: storage,
});

export const addJava = (value: JavaVersion): ActionTypeMix => ({
  type: ADD_JAVA,
  value: value,
});

export const addAllowProperite = (value: AllowProperite): ActionTypeMix => ({
  type: ADD_ALLOW_PROPERITE,
  value: value,
});

export const setVersionsAndCores = (value: Versions[]): ActionTypeMix => ({
  type: SET_VERSIONS,
  value: value
});
