import { Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../paths";

export const ServerURLEditPage = () => {
  const navigate = useNavigate();


    const edit_server = (srv: string) => {
      localStorage.setItem("server_adress", srv);
      navigate(Paths.home);
      window.location.reload();
    };

  return (
    <div>
      {" "}
      <Input
        onBlur={(e) => edit_server(e.target.value)}
        placeholder="Адрес сервера"
        defaultValue={`${localStorage.getItem("server_adress")}`}
      />
    </div>
  );
};
