import React, { useEffect, useState } from "react";
import { apiGetServerProperites } from "../../../../../../app/api";
import { MinecrServer } from "../../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Input, Select, Typography } from "antd";
import { TRootReducer } from "../../../../../../app/redux/common/root";
import Table, { ColumnsType } from "antd/es/table";
import { editFileProperiteThunc } from "../../../../../../app/redux/common/reducers/servers/thuncs";
import useDeviceDetect from "../../../../../../app/hooks/isMobile";

type Props = {
  serverInfo: MinecrServer;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

type Pr = {
  title: string;
  value: string;
  name: string;
  type: string;
};

export const ProperitesServerPage = ({ serverInfo, notify }: Props) => {
  const dispatch = useDispatch();
  const { isMobile } = useDeviceDetect();
  const settings_state = useSelector((state: TRootReducer) => state.settings);
  const [dataP, setDataP] = useState<Pr[]>([]);

  const editValue = (param: string, value: string) => {
    editFileProperiteThunc(serverInfo.id, param, value, dispatch, notify);
    getPr();
  };

  const getPr = async () => {
    await apiGetServerProperites(serverInfo.id)
      .then((res: any) => {
        setDataP(res.data);
      })
      .catch((err: any) => {
        if (err.code === "ERR_NETWORK") {
          notify.setError("Нет связи с сервером!");
        } else if (err.code === 400) {
          notify.setError(err.response.data.message);
        } else {
          notify.setError(err.response.data.message);
        }
      });
  };

  const inputParam = (rec: Pr) => {
    switch (rec.type) {
      case "chbx":
        return <>
          <Checkbox
            checked={rec.value == "true"}
            onChange={(e) => {
              console.log(e);
              editValue(rec.name, `${e.target.checked}`);
            }}
          />
        </>;
        break;
      default:
        return <>
          <Input
            placeholder="Empty"
            variant="borderless"
            defaultValue={rec.value}
            onBlur={(e) => {
              editValue(rec.name, e.target.value);
            }}
          />
        </>;
        break;
    }
  };

  const columns: ColumnsType<Pr> = [
    {
      title: "Параметр",
      dataIndex: "name",
      key: "path",

      render: (_, record) => (
        <>
          <Typography>
            {record.title} ({record.name})
          </Typography>
        </>
      ),
    },
    {
      title: "Значение",
      dataIndex: "value",
      key: "name",
      width: "40%",
      render: (_, record) => <>{inputParam(record)}</>,
    },
  ];

  useEffect(() => {
    getPr();
  }, []);

  return (
    <Table
      style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
      size={isMobile ? "small" : "middle"}
      dataSource={dataP}
      columns={columns}
    ></Table>
  );
};

/*<Form>
      {settings_state.allow_properite.filter((el) => el.id !== -1).map((el) => (
        <>
          <Form.Item name={el.value}>
            <Input
              prefix={
                <Space>
                  <EditOutlined />
                  {el.name}
                </Space>
              }
              defaultValue={''}
            />
          </Form.Item>
        </>
      ))}
    </Form>*/
