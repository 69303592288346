import { Button, Input, Layout, Popconfirm, Table } from "antd";
import styles from "./index.module.css";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { TRootReducer } from "../../../../app/redux/common/root";
import { useDispatch, useSelector } from "react-redux";
import { JavaVersion } from "../../../../types";
import { addJavaThunc, editJavaThunc, removeJavaThunc } from "../../../../app/redux/common/reducers/settings/thuncs";

type Props = {
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const JavaVersionsTable = ({ notify }: Props) => {
  const dispatch = useDispatch();
  const settings_state = useSelector((state: TRootReducer) => state.settings);

  const removeJava = (java: JavaVersion) => {
    removeJavaThunc(java.id, dispatch, notify);
  };

  const addJava = (java: JavaVersion) => {
    addJavaThunc({id: 0, name: ' ', path: ' '}, dispatch, notify);
  };

  const editJava = (java: JavaVersion) => {
    editJavaThunc(java, dispatch, notify);
  };

  const columns: ColumnsType<JavaVersion> = [
    {
      title: "Путь",
      dataIndex: "path",
      key: "path",

      render: (_, record) => (
        <>
          <Input
            placeholder="Path"
            variant="borderless"
            defaultValue={record.path}
            disabled={record.id === -1}
            onBlur={(e) => {
              let tmp: JavaVersion = { 
                ...record,
                path: e.target.value,
              };
              editJava(tmp);
            }}
          />
        </>
      ),
    },
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (_, record) => (
        <>
          <Input
            placeholder="Name"
            variant="borderless"
            defaultValue={record.name}
            disabled={record.id === -1}
            onBlur={(e) => {
              let tmp: JavaVersion = {
                ...record,
                name: e.target.value,
              };
              editJava(tmp);
            }}
          />
        </>
      ),
    },
    {
      title: "Действие",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <>
          {record.id === -1 ? (
            <>
            <Button
                shape="round"
                danger={false}
                onClick={() => addJava(record)}
                icon={<PlusOutlined />}
              ></Button>
            </>
          ) : (
            <Popconfirm
              title="Удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={(e) => removeJava(record)}
            >
              <Button
                shape="round"
                danger={true}
                onClick={() => null}
                icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Layout.Header className={styles.header}>Версии Java</Layout.Header>
      <Table
        style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
        dataSource={settings_state.java_versions}
        columns={columns}
      ></Table>
    </>
  );
};
