import { Layout } from "antd";
import styles from "./index.module.css";
import { Header } from "../header";
import { useDispatch, useSelector } from "react-redux";
import { TRootReducer } from "../../app/redux/common/root";
import { useEffect, useState } from "react";
import { currentThunc } from "../../app/redux/common/reducers/auth/thuncs";
import {
  getAllServersUserThunc,
} from "../../app/redux/common/reducers/servers/thuncs";
import { AuthLayout } from "../authLayout";
import { getAllSettingsThunc } from "../../app/redux/common/reducers/settings/thuncs";

type Props = {
  children: React.ReactNode;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const CustomLayout = ({ children, notify }: Props) => {
  const dispatch = useDispatch();

  const user_state = useSelector((state: TRootReducer) => state.user);
  const statuses_state = useSelector((state: TRootReducer) => state.status);
  const [openLoginDrawer, setOpenLoginDrawer] = useState(false);
  const hideLoginDrawer = () => {
    if (openLoginDrawer) setOpenLoginDrawer(false);
  };
  const showLoginDrawer = () => {
    if (!openLoginDrawer) setOpenLoginDrawer(true);
  };
  const [openRegisterDrawer, setOpenRegisterDrawer] = useState(false);
  const hideRegisterDrawer = () => {
    setOpenRegisterDrawer(false);
  };
  const showRegisterDrawer = () => {
    setOpenRegisterDrawer(true);
  };

  useEffect(() => {
    currentThunc(dispatch, notify);
  }, []);

  useEffect(() => {
    if (!statuses_state.authProcess) {
      if (!user_state.user) {
        showLoginDrawer();
      } else {
        hideLoginDrawer();
        hideRegisterDrawer();
      }
    }
  }, [user_state.user, statuses_state.authProcess]);

  useEffect(() => {
    if (user_state.user) {
      getAllServersUserThunc(dispatch, notify);

      getAllSettingsThunc(dispatch, notify);
    }
  }, [user_state.user]);

  return (
    <div className={styles.main}>
      <Header notify={notify} onLogin={showLoginDrawer} onRegister={showRegisterDrawer} />

      {user_state.user ? (
        <Layout.Content style={{ height: "100%" }}>{children}</Layout.Content>
      ) : (
        <>Not Authorized</>
      )}
      <AuthLayout
        onCloseLogin={hideLoginDrawer}
        onCloseReg={hideRegisterDrawer}
        onOpenReg={showRegisterDrawer}
        openLogin={openLoginDrawer}
        openReg={openRegisterDrawer}
        notify={notify}
      />
    </div>
  );
};

/**/
