import React, { useEffect, useState } from "react";
import { MinecrServer, ServerStatuses } from "../../../../../../types";
import { Button, Popconfirm, Space, Spin, Typography } from "antd";
import { apiDownloadWorldFromID } from "../../../../../../app/api";
import { removeServerWorldThunc } from "../../../../../../app/redux/common/reducers/servers/thuncs";
import { useDispatch } from "react-redux";

type Props = {
  serverInfo: MinecrServer;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
  socket: any;
  status: string;
};

export const WorldServerManager = ({ status, serverInfo, notify, socket }: Props) => {
  const dispatch = useDispatch();
  const [worldSize, setWorldSize] = useState("");

  const download_world_zip = () => {
    apiDownloadWorldFromID(serverInfo.id).then((res: any) => {
      const downloadUrl = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/zip" })
      );
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "world.zip"); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  const removeWorld = () => {
    removeServerWorldThunc(serverInfo.id, dispatch, notify);
  };

  useEffect(() => {
    socket.emit(`serverInfo:world_size`, serverInfo.id);
    socket.on(`${serverInfo.id}:world_size`, (message: any) => {
      setWorldSize(message);
    });
  }, [socket]);

  return (
    <>
      <Button style={{ width: "100%" }} onClick={download_world_zip}>
        <Space>
          <Typography>Скачать мир</Typography>
          {worldSize === "" ? <Spin /> : <>{worldSize}</>}
        </Space>{" "}
      </Button>
      <Popconfirm
        title="Удалить?"
        okText="Да"
        cancelText="Нет"
        onConfirm={removeWorld}
        
      >
        <Button disabled={parseInt(status) !== ServerStatuses["Выключен"]} style={{ width: "100%", top: "90%" }} danger>
          Удалить мир
        </Button>
      </Popconfirm>
    </>
  );
};
