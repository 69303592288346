import { useEffect, useState } from "react";
import { TRootReducer } from "../../../../app/redux/common/root";
import { useSelector } from "react-redux";
import { Button, Form, Input, Layout, Space } from "antd";
import { FolderOpenOutlined } from "@ant-design/icons";
import styles from "./index.module.css";

type Props = {
  notify?: {
    setSuccses: (msg: string) => void,
    setError: (msg: string) => void;
  }
}

export const PutiPage = ({notify}: Props) => {
  const [cores_dir, setCoresDir] = useState(".");
  const settings_state = useSelector((state: TRootReducer) => state.settings);
  const statuses = useSelector((state: TRootReducer) => state.status);

  const editCoresDirPole = (value: string | undefined) => {
    if (value) setCoresDir(value);
  };

  const saveSettings = (data: any) => {
    console.log(data);
  };

  useEffect(() => {
    editCoresDirPole(settings_state.setting?.cores_folder);
  }, [settings_state.setting, statuses.settingsLoading]);

  return (
    <>
    <Layout.Header className={styles.header}>Настройки путей</Layout.Header>
    <Form onFinish={saveSettings} title="Настройки" style={{ margin: "10px" }}>
      <Form.Item name="cores_dir">
        <Input
          prefix={
            <Space>
              <FolderOpenOutlined />
              Папка с ядрами:
            </Space>
          }
          value={cores_dir}
          size="large"
          placeholder={cores_dir}
        />
      </Form.Item>

      <Button title="Сохранить" type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form>
    </>
    
  );
};
