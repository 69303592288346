import { initialState } from "./initial";
import { ADD_ALLOW_PROPERITE, ADD_JAVA, ActionTypeMix, SET_SETTINGS, SET_VERSIONS } from "./types";
import { IState } from "./iState";

export default (state = initialState, action: ActionTypeMix): IState => {
  switch (action.type) {
    case SET_SETTINGS:
      return action.value;
    case ADD_JAVA:
      return { ...state, java_versions: [...state.java_versions, action.value] };
    case ADD_ALLOW_PROPERITE:
      return { ...state, allow_properite: [...state.allow_properite, action.value] };
    case SET_VERSIONS:
      return { ...state, versions: action.value };
    default:
      return state;
  }
};
