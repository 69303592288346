import { Card, Flex, Tag } from "antd";
import { UserRoles } from "../../types";
import { useSelector } from "react-redux";
import { TRootReducer } from "../../app/redux/common/root";
import { AvatarCardUpload } from "./components/avatarUpload";

type Props = {
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const UserCard = ({ notify }: Props) => {
  const user_state = useSelector((state: TRootReducer) => state.user);
  const servers = useSelector((state: TRootReducer) => state.servers);

  const edit_server = (srv: string) => {
    localStorage.setItem("server_adress", srv);
  }

  return (
    <>
      {user_state.user ? (
        <>
          <Card title={user_state.user?.name}>
            <p><Flex vertical={false}><AvatarCardUpload notify={notify}/><></></Flex></p>
            <p>
              Серверов: {servers.servers.length}/{user_state.user.user_servers_limit}
            </p>
            <p>
              Роль:{" "}
              <Tag color={user_state.user.role === 0 ? "green" : "red"}>
                {UserRoles[user_state.user.role]}
              </Tag>
            </p>
          </Card>
        </>
      ) : (
        <>Ошибка чтения пользователя</>
      )}
    </>
  );
};
