import { MinecrServer } from '../../../../../types';
import { SET_SERVER, SET_SERVERS } from './types';

export const setServers = (p: MinecrServer[]) => ({
  type: SET_SERVERS,
  value: p,
});

export const setServer = (p: MinecrServer) => ({
  type: SET_SERVER,
  value: p,
});
