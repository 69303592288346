import {
  CaretRightOutlined,
  DeleteOutlined,
  MinusSquareFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { Card, Col, Popconfirm, Progress, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { MinecrServer, ServerStatuses } from "../../../../types";
import { UserTag } from "../../../micro/userTag";
import styles from "./index.module.css";
import {
  removeServerThinc,
  startServerThunc,
  stopServerThunc,
} from "../../../../app/redux/common/reducers/servers/thuncs";
import { useDispatch } from "react-redux";
import { FullStatsModal } from "../modalFullStats";

type Props = {
  serverInfo: MinecrServer;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
  socket: any;
};

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: "20px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export const MinecraftServerCard = ({ socket, serverInfo, notify }: Props) => {
  const dispatch = useDispatch();
  const [ramUsage, setRamUsage] = useState(0);
  const [ramProgress, setRamProgress] = useState<number>(0);
  const [openModal, setOpenModal] = useState(false);
  const [serverStatus, setServerStatus] = useState("0");
  const [cardLoad, setCardLoad] = useState(false);

  const showModal = () => {
    socket.emit("serverInfo:start_terminal", serverInfo.id);
    setOpenModal(true);
  };

  const hideModal = () => {
    setOpenModal(false);
  };

  const removeServer = () => {
    removeServerThinc(serverInfo.id, dispatch, notify);
  };

  const startServer = () => {
    startServerThunc(serverInfo.id, dispatch, notify);
  };

  const stopServer = () => {
    stopServerThunc(serverInfo.id, dispatch, notify);
  };

  useEffect(() => {
    socket.emit("serverInfo:memory", serverInfo.id);
    socket.emit("serverInfo:status", serverInfo.id);
  }, []);

  const getServerIp = () => {
    const tmp = localStorage.getItem("server_adress")?.split(":")[1].split("/");
    if (tmp) return `${tmp[tmp?.length - 1]}:${serverInfo.port}`;
  };

  useEffect(() => {
    socket.on(`${serverInfo.id}:memory`, (message: any) => {
      setRamUsage(message);
      setRamProgress(Math.floor((message / parseInt(serverInfo.memory)) * 100));
    });
    socket.on(`${serverInfo.id}:status`, (message: any) => {
      setServerStatus(message);
      setCardLoad(true);
    });
    
  }, [socket]);

  return (
    <div className={styles.main}>
      {serverInfo.error ? (
        <>
          <Card
            style={{ width: 300 }}
            title={"Ошибка чтения"}
            actions={[
              <Popconfirm
                title="Удалить?"
                description="Внимание, удалятся все файлы!"
                okText="Да"
                cancelText="Нет"
                disabled={serverStatus != "0"}
                onConfirm={(e) => removeServer()}
              >
                <DeleteOutlined alt="Удалить" style={{ color: "red" }} key="deleteServer" />
              </Popconfirm>,
            ]}
          >
            <Row>
              <Col>
                Система не смогла прочитать файлы вашего сервера по причине их отсутствия или
                повреждения!
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                Создатель: <UserTag user={serverInfo.user} />{" "}
              </Col>
            </Row>
          </Card>
        </>
      ) : (
        <>
          <Card
            style={{
              width: 300,
            }}
            title={serverInfo.name}
            actions={
              cardLoad ? ([<>
                {serverStatus === `${ServerStatuses.Выключен}` ? (
                  <>
                    <CaretRightOutlined onClick={startServer} style={{ color: "green" }} />
                  </>
                ) : (
                  <></>
                )}
                {serverStatus === `${ServerStatuses.Загружается}` ? (
                  <>
                    <Spin size="small" style={{ color: "orange" }} />
                  </>
                ) : (
                  <></>
                )}
                {serverStatus === `${ServerStatuses.Активен}` ? (
                  <>
                    <MinusSquareFilled onClick={stopServer} style={{ color: "red" }} />
                  </>
                ) : (
                  <></>
                )}
              </>,
              <SettingOutlined onClick={showModal} key="setting" />,
              <Popconfirm
                title="Удалить?"
                description="Внимание, удалятся все файлы!"
                okText="Да"
                cancelText="Нет"
                disabled={serverStatus != "0"}
                onConfirm={(e) => removeServer()}
              >
                <DeleteOutlined
                  alt="Удалить"
                  style={serverStatus == "0" ? { color: "red" } : { color: "gray" }}
                  key="deleteServer"
                />
              </Popconfirm>]) : ([<>
              <Spin></Spin></>])
              
            }
          >
            <Row>
              <Col span={12}>Версия: {serverInfo.version}</Col>
              <Col span={12}>Ядро: {serverInfo.ver_core}</Col>
            </Row>
            <Row>
              <Col span={12}>
                Статус:{" "}
                <Progress
                  size={18}
                  type="circle"
                  percent={100}
                  status={
                    parseInt(serverStatus) === ServerStatuses.Активен ? "success" : "exception"
                  }
                />{" "}
                <br></br>
                {ServerStatuses[parseInt(serverStatus)]}
              </Col>
              <Col span={12}>
                Память: <Progress size={18} type="circle" percent={ramProgress} />
                {ramUsage}/{serverInfo.memory}
                GB
              </Col>
            </Row>
            <Row>
              <Col span={12}>Порт: {serverInfo.port}</Col>
            </Row>
            <Row>
              <Col span={24}>
                Создатель: <UserTag user={serverInfo.user} />{" "}
              </Col>
            </Row>
            <Row>
              <Col span={24}>IP: {getServerIp()}</Col>
            </Row>
            <FullStatsModal
              status={serverStatus}
              memoryUse={ramUsage}
              serverInfo={serverInfo}
              notify={notify}
              socket={socket}
              isOpen={openModal}
              hideThis={hideModal}
            />
          </Card>
        </>
      )}
    </div>
  );
};
