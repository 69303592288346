import { Avatar, Button, Col, Drawer, Popover, Row, Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Paths } from "../../../paths";
import { UserCard } from "../../userCard";
import {
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
  AlignCenterOutlined,
  SettingOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { TRootReducer } from "../../../app/redux/common/root";
import { useSelector } from "react-redux";
import { UserRoles } from "../../../types";

type Prop = {
  onClose: () => void;
  open: boolean;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
  onRegister: () => void;
  onLogin: () => void;
  onLogoutClick: () => void;
};

export const HeaderMobileMenu = ({
  onClose,
  open,
  notify,
  onLogin,
  onLogoutClick,
  onRegister,
}: Prop) => {
  const user = useSelector((state: TRootReducer) => state.user);
  return (
    <>
      <Drawer width={280} onClose={onClose} key="lmobileheaderdrawer" title="Меню" open={open}>
        <>
          {user.user ? (
            <>
              <Row>
                <Col>
                  <Popover content={<UserCard notify={notify} />} title={<></>}>
                    <Button
                      type="text"
                      ghost
                      size="large"
                      icon={
                        <Avatar
                          size="small"
                          src={<img src={`${user.user.avatar}`} alt="avatar" />}
                        />
                      }
                    >
                      {user.user?.name}
                    </Button>
                  </Popover>
                </Col>
              </Row>

              {user.user.role == UserRoles.ADMIN ? (
                <>
                  <Row>
                    <Col>
                      <Link to={Paths.settings}>
                        <Button size="large" type="text" ghost icon={<SettingOutlined />}>
                          Настройки
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
              <Button
                type="text"
                size="large"
                ghost
                icon={<LogoutOutlined />}
                onClick={onLogoutClick}
              >
                Выйти
              </Button>
            </>
          ) : (
            <>
              <Row>
                <Link to={Paths.register}>
                  <Button
                    type="text"
                    size="large"
                    ghost
                    onClick={onRegister}
                    icon={<UserOutlined />}
                  >
                    Регистрация
                  </Button>
                </Link>
              </Row>
              <Row>
                <Link to={Paths.login}>
                  <Button type="text" size="large" ghost onClick={onLogin} icon={<LoginOutlined />}>
                    Войти
                  </Button>
                </Link>
              </Row>
            </>
          )}
        </>
      </Drawer>
    </>
  );
};
