import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { Avatar, message, Upload } from "antd";
import { useState } from "react";
import { TRootReducer } from "../../../../app/redux/common/root";
import { useDispatch, useSelector } from "react-redux";
import { currentThunc } from "../../../../app/redux/common/reducers/auth/thuncs";
import { GlobalURL } from "../../../../app/api";
import ImgCrop from "antd-img-crop";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

type Props = {
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export const AvatarCardUpload = ({ notify }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user_state = useSelector((state: TRootReducer) => state.user);

  const handleChange: UploadProps["onChange"] = (info) => {
    console.log(info);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      currentThunc(dispatch, notify);
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Загрузить аватар</div>
    </button>
  );

  const headers = {
    authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new window.Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <>
      <ImgCrop rotationSlider>
        <Upload
          name="avatar"
          listType="picture-circle"
          className="avatar-uploader"
          showUploadList={false}
          action={`${GlobalURL}/api/users/uploadAvatar`}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          onPreview={onPreview}
          headers={headers}
        >
          {user_state.user?.avatar ? (
            <Avatar size={100} src={<img src={user_state.user.avatar} alt="avatar" />} />
          ) : (
            uploadButton
          )}
        </Upload>
      </ImgCrop>
    </>
  );
};
