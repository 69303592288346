import React, { useEffect, useRef, useState } from "react";
import { MinecrServer } from "../../../../../../types";
import { Form, Input } from "antd";
import { TerminalWindow } from "./components/window";
import { sendCommandToServerThunc } from "../../../../../../app/redux/common/reducers/servers/thuncs";
import { useDispatch } from "react-redux";
import useDeviceDetect from "../../../../../../app/hooks/isMobile";

type Props = {
  serverInfo: MinecrServer;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
  socket: any;
};

export const TerminalServer = ({ serverInfo, notify, socket }: Props) => {
  const dispatch = useDispatch();
  
  const [outputData, setOutputData] = useState("");
  const [form] = Form.useForm();

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  let lines = "";

  const addLineOutput = (line: string) => {
    if (!lines.includes(line)) setOutputData((lines += `${line}\n`));
  };

  const sendCommand = (data: any) => {
    sendCommandToServerThunc(serverInfo.id, data, dispatch, notify);
    form.resetFields(['command']);
  };

  useEffect(()=>{
    socket.emit("serverInfo:start_terminal", serverInfo.id);
  }, []);

  useEffect(() => {
    socket.on(`${serverInfo.id}:terminal`, (message: any) => {
      scrollToBottom();
      addLineOutput(message);
    });
  }, [socket]);

  return (
    <>
      <TerminalWindow data={outputData} bottomRef={messagesEndRef}/>
      <Form form={form} onFinish={sendCommand}>
        <Form.Item name="command">
          <Input placeholder="Команда" />
        </Form.Item>
      </Form>
    </>
  );
};
