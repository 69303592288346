import { Avatar, Popover, Space, Tag } from "antd";
import React from "react";
import { UserInfo, UserRoles } from "../../../types";

type Props = {
  user: UserInfo;
};

export const UserTag = ({ user }: Props) => {
  return (
    <Popover
      content={
        <>
          <p>{UserRoles[user.role]}</p>
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            src={<img src={`${user.avatar}`} alt="avatar" />}
          />
        </>
      }
      title={user.name}
    >
      <Tag color={user.role === 0 ? "green" : "red"}>
        <Space>
          <Avatar size="small" src={<img src={`${user.avatar}`} alt="avatar" />} />
          {user.name}
        </Space>
      </Tag>
    </Popover>
  );
};
