import { Form, Input } from "antd";

type Props = {
  name: string;
  placeholder: string;
  type?: string;
  className?: string;
};

export const FormInput = ({
  type = 'text',
  name,
  placeholder,
  className
}: Props) => {
  return (
    <Form.Item
      name={name}
      rules={[{ required: true, message: "Обязательное поле" }]}
      shouldUpdate={ true }
      className={className}
    >
      <Input
        placeholder={placeholder}
        type={ type }
        size="large"
      />
    </Form.Item>
  );
};