import { CheckOutlined, CloseOutlined, EditOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popover, Select, Space } from "antd";
import React, { useState } from "react";
import { MinecrServer } from "../../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { TRootReducer } from "../../../../../../app/redux/common/root";
import { editServerThunc } from "../../../../../../app/redux/common/reducers/servers/thuncs";
import useDeviceDetect from "../../../../../../app/hooks/isMobile";

type Props = {
  serverInfo: MinecrServer;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const GeneralMobileSettingsMenu = ({ serverInfo, notify }: Props) => {
  const dispatch = useDispatch();
  const settings_state = useSelector((state: TRootReducer) => state.settings);
  const [portInput, setPortInput] = useState("");

  const onChangePort = (e: any) => {
    let value = e;
    setPortInput(value);
  };

  const editServer = (data: any) => {
    editServerThunc(serverInfo.id, data, dispatch, notify);
  };

  const setPortPrefix = () => {
    const isUsing = settings_state.using_ports.find((item) => {
      return item.port === portInput;
    });
    if (portInput === "") {
      return <WarningOutlined style={{ color: "yellow" }} />;
    }
    if (isUsing) {
      return (
        <Popover title="Порт занят" content={<>Используйте другой порт</>}>
          <CloseOutlined style={{ color: "red" }} />
        </Popover>
      );
    } else {
      return <CheckOutlined style={{ color: "green" }} />;
    }
  };

  return (
    <Form onFinish={editServer} title="Основные" style={{ margin: "10px" }}>
      <Form.Item name="name" rules={[{ required: true, message: "Обязательное поле" }]}>
        <Input
          size="large"
          prefix={
            <Space>
              <EditOutlined />
              Имя сервера:
            </Space>
          }
          defaultValue={serverInfo.name}
        />
      </Form.Item>

      <Space>
        <EditOutlined />
        Память в GB:
        <Form.Item
          rules={[{ required: true, message: "Обязательное поле" }]}
          name="memory"
          style={{ width: "100%" }}
        >
          <Select
            size="large"
            placeholder="Количество памяти в Gb"
            defaultValue={serverInfo.memory}
            options={[
              { name: "1", value: "1" },
              { name: "2", value: "2" },
              { name: "3", value: "3" },
              { name: "4", value: "4" },
              { name: "5", value: "5" },
            ]}
          />
        </Form.Item>
      </Space>
      <Space>
        <EditOutlined />
        Порт: {setPortPrefix()}
        <Form.Item
          name="port"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Обязательное поле" }]}
        >
          <Input.OTP
            size="large"
            onChange={onChangePort}
            prefix={"sd"}
            length={5}
            defaultValue={serverInfo.port}
            formatter={(str) => str.toUpperCase()}
          />
        </Form.Item>
      </Space>

      <Form.Item
        name="javaId"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Обязательное поле" }]}
      >
        <Select
          size="large"
          placeholder="Версия Java"
          defaultValue={serverInfo.javaId}
          options={settings_state.java_versions
            .filter((el) => el.id != -1)
            .map((value) => {
              return { value: value.id, label: value.name };
            })}
        />
      </Form.Item>

      <Button
        size="large"
        title="Сохранить"
        style={{ width: "100%" }}
        type="primary"
        htmlType="submit"
      >
        Сохранить
      </Button>
    </Form>
  );
};
