import { Form, Input } from "antd";
import { NamePath } from "antd/es/form/interface";

type Props = {
  name: string;
  placeholder: string;
  dependecive?: NamePath;
};

export const PasswordInput = ({ name, placeholder, dependecive }: Props) => {
  return (
    <>
      <Form.Item
        name={name}
        dependencies={dependecive}
        hasFeedback
        rules={[
          { required: true, message: "Обязательно!" },
          ({ getFieldValue }) => ({
            validator(_, value){
                if(!value){
                    return Promise.resolve();
                }
                if(name === "confirmPassword"){
                    if(!value || getFieldValue('password') === value){
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Пароли не совпадают!'));
                } else {
                    if(value.length < 8){
                        return Promise.reject(new Error('Пароль должен быть не менее 8ми символов!!'));
                    }
                    return Promise.resolve();
                }
                
            }
          }),
        ]}
      >
        <Input.Password placeholder={placeholder} size="large" />
      </Form.Item>
    </>
  );
};