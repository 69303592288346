import { Dispatch } from "@reduxjs/toolkit";
import { AllowProperite, JavaVersion, NotyfyType } from "../../../../../types";
import {
  apiAddJava,
  apiAddProperite,
  apiEditJava,
  apiEditProperite,
  apiGetAllSettings,
  apiGetVersions,
  apiRemoveAllowProperite,
  apiRemoveJava,
} from "../../../../api";
import { addAllowProperite, addJava, setSettings, setVersionsAndCores } from "./actions";
import { setGetSettingsStatus } from "../status/actions";

export const getAllSettingsThunc = async (dispatch: Dispatch<any>, notyfy: NotyfyType) => {
  dispatch(setGetSettingsStatus(true));

  await apiGetAllSettings()
    .then(async (res: any) => {
      const tmp_settings = res.data;
      dispatch(setSettings(tmp_settings));
      await apiGetVersions().then((value: any) => {
        dispatch(setVersionsAndCores(value.data));
      });
      dispatch(addJava({ id: -1, path: "", name: "" }));
      dispatch(addAllowProperite({ id: -1, value: "", name: "", type: "string"}));
      dispatch(setGetSettingsStatus(false));
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        notyfy.setError("Нет связи с сервером!");
      } else if (err.code === 400) {
        notyfy.setError(err.response.data.message);
      } else {
        notyfy.setError(err.response.data.message);
      }
      dispatch(setGetSettingsStatus(false));
    });
};

export const removeJavaThunc = async (id: number, dispatch: Dispatch<any>, notyfy: NotyfyType) => {
  dispatch(setGetSettingsStatus(true));
  await apiRemoveJava(id)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data}`);
      getAllSettingsThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        notyfy.setError("Нет связи с сервером!");
      } else if (err.code === 400) {
        notyfy.setError(err.response.data.message);
      } else {
        notyfy.setError(err.response.data.message);
      }
      dispatch(setGetSettingsStatus(false));
    });
};

export const addJavaThunc = async (
  java: JavaVersion,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  dispatch(setGetSettingsStatus(true));
  await apiAddJava(java)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data.message}`);
      getAllSettingsThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        notyfy.setError("Нет связи с сервером!");
      } else if (err.code === 400) {
        notyfy.setError(err.response.data.message);
      } else {
        notyfy.setError(err.response.data.message);
      }
      dispatch(setGetSettingsStatus(false));
    });
};

export const editJavaThunc = async (
  java: JavaVersion,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  dispatch(setGetSettingsStatus(true));
  await apiEditJava(java)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data.message}`);
      getAllSettingsThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        notyfy.setError("Нет связи с сервером!");
      } else if (err.code === 400) {
        notyfy.setError(err.response.data.message);
      } else {
        notyfy.setError(err.response.data.message);
      }
      dispatch(setGetSettingsStatus(false));
    });
};

export const removeAllowProperiteThunc = async (
  id: number,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  dispatch(setGetSettingsStatus(true));
  await apiRemoveAllowProperite(id)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data}`);
      getAllSettingsThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        notyfy.setError("Нет связи с сервером!");
      } else if (err.code === 400) {
        notyfy.setError(err.response.data.message);
      } else {
        notyfy.setError(err.response.data.message);
      }
      dispatch(setGetSettingsStatus(false));
    });
};

export const addAllowProperiteThunc = async (
  value: AllowProperite,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  dispatch(setGetSettingsStatus(true));
  await apiAddProperite(value)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data.message}`);
      getAllSettingsThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        notyfy.setError("Нет связи с сервером!");
      } else if (err.code === 400) {
        notyfy.setError(err.response.data.message);
      } else {
        notyfy.setError(err.response.data.message);
      }
      dispatch(setGetSettingsStatus(false));
    });
};

export const editAllowProperiteThunc = async (
  value: AllowProperite,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  dispatch(setGetSettingsStatus(true));
  await apiEditProperite(value)
    .then((res: any) => {
      notyfy.setSuccses(`${res.data.message}`);
      getAllSettingsThunc(dispatch, notyfy);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        notyfy.setError("Нет связи с сервером!");
      } else if (err.code === 400) {
        notyfy.setError(err.response.data.message);
      } else {
        notyfy.setError(err.response.data.message);
      }
      dispatch(setGetSettingsStatus(false));
    });
};



/*export const getAvatarImageThunc = async (
  value: string,
  dispatch: Dispatch<any>,
  notyfy: NotyfyType
) => {
  return `data:;base64,${await apiGetAvatar(value).data}`;
};*/
