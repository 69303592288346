import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { ConfigProvider, notification, theme } from "antd";
import { Paths } from "./paths";
import { CustomLayout } from "./components/rootLayout";
import { Provider } from "react-redux";
import { store } from "./app/redux/store/store";
import { SettingsPage } from "./components/settingsPage";
import { CheckCircleOutlined, DoubleRightOutlined, WarningOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { MinecraftServerPage } from "./components/minecraftServersPage";
import io from 'socket.io-client';
import { socketURL } from "./app/api";
import { ServerURLEditPage } from "./components/serverEditPage";
const socket = io(socketURL);

function App() {
  const [error, setError] = useState("");
  const [sucses, setSucsses] = useState("");
  const [apiNotification, contextHolderNotification] = notification.useNotification();

  const notyfy_c = {
    setError: setError,
    setSuccses: setSucsses,
  };

  useEffect(()=>{
    if (!localStorage.getItem("server_adress") || localStorage.getItem("server_adress") === null) {
      localStorage.setItem("server_adress", `https://minecraft.indexit407.ru:8000`); //https://minecraft.indexit407.ru:8000
      window.location.reload();
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: Paths.home,
      element: (
        <CustomLayout notify={notyfy_c}>
          <MinecraftServerPage socket={socket} notify={notyfy_c} />
        </CustomLayout>
      ),
    },
    {
      path: Paths.settings,
      element: (
        <CustomLayout notify={notyfy_c}>
          <SettingsPage notify={notyfy_c} />
        </CustomLayout>
      ),
    },
    {
      path: Paths.back,
      element: (
        <>
        <ServerURLEditPage />
        </>
      )
    }
  ]);

  const openNotification = (type: string = "error" || "success", msg: string) => {
    if (msg == "") return;
    let title = "";
    let icon = <></>;
    switch (type) {
      case "success":
        title = "Успешно!";
        icon = <CheckCircleOutlined style={{ color: "green" }} />;
        break;
      case "error":
        title = "Внимание!";
        icon = <WarningOutlined style={{ color: "red" }} />;
        break;
      default:
        break;
    }
    apiNotification.open({
      message: title,
      description: (
        <>
          <DoubleRightOutlined style={{ color: "#FFFF00" }} /> {msg}
        </>
      ),
      duration: 0,
      placement: "bottom",
      icon: icon,
    });
  };

  useEffect(() => {
    openNotification("error", error);
  }, [error]);

  useEffect(() => {
    openNotification("success", sucses);
  }, [sucses]);

  return (
    <div className="App">
      <Provider store={store}>
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
          }}

        >
          
          {contextHolderNotification}
          <RouterProvider router={router} />
        </ConfigProvider>
      </Provider>
    </div>
  );
}

export default App;
