import Sider from "antd/es/layout/Sider";
import { MinecrServer } from "../../../../../../types";
import useDeviceDetect from "../../../../../../app/hooks/isMobile";
import { FullStatsMenu } from "./menu";

type Props = {
  setMenuPage: (page: number) => void;
  currentPage: number;
  server: MinecrServer;
  status: string;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const StatsMenu = ({ setMenuPage, currentPage, notify, server, status }: Props) => {
  const { isMobile } = useDeviceDetect();

  return (
    <>
      {isMobile ? (
        <>
          <FullStatsMenu
            setMenuPage={setMenuPage}
            currentPage={currentPage}
            notify={notify}
            server={server}
            status={status}
            mobile={isMobile}
          />
        </>
      ) : (
        <>
          <Sider>
            <FullStatsMenu
              setMenuPage={setMenuPage}
              currentPage={currentPage}
              notify={notify}
              server={server}
              status={status}
              mobile={isMobile}
            />
          </Sider>
        </>
      )}
    </>
  );
};
