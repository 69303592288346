import {
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
  AlignCenterOutlined,
  SettingOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, Layout, Menu, MenuProps, Popover, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import style from "./index.module.css";
import { Paths } from "../../paths";
import { useState } from "react";
import { TRootReducer } from "../../app/redux/common/root";
import { logoutThunc } from "../../app/redux/common/reducers/auth/thuncs";
import { UserRoles } from "../../types";
import { UserCard } from "../userCard";
import useDeviceDetect from "../../app/hooks/isMobile";
import { HeaderMobileMenu } from "../mobile/headerMobileMenu";

interface HeaderProps {
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
  onLogin: () => void;
  onRegister: () => void;
}

export const Header = ({ notify, onLogin, onRegister }: HeaderProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useDeviceDetect();
  const user = useSelector((state: TRootReducer) => state.user);
  const [openLoginDrawer, setOpenLoginDrawer] = useState(false);
  const [openMobileMenuDrawer, setOpenMobileMenuDrawer] = useState(false);
  const showLoginDrawer = () => {
    setOpenLoginDrawer(true);
  };

  const onLogoutClick = () => {
    logoutThunc(dispatch);
    showLoginDrawer();
  };

  const onMobileMenuOpen = () => {
    setOpenMobileMenuDrawer(true);
  };

  const onMobileMenuClose = () => {
    setOpenMobileMenuDrawer(false);
  };

  return (
    <Layout.Header className={style.header}>
      <Space>
        <Link to={Paths.home}>
          <Button type="text" ghost size="large" icon={<AlignCenterOutlined />}>
            Главная
          </Button>
        </Link>
      </Space>
      {isMobile ? (
        <>
          <Button type="text" onClick={onMobileMenuOpen} ghost icon={<MenuOutlined />}>
            Меню
          </Button>
          <HeaderMobileMenu
            open={openMobileMenuDrawer}
            onClose={onMobileMenuClose}
            onRegister={onRegister}
            onLogin={onLogin}
            notify={notify}
            onLogoutClick={onLogoutClick}
          />
        </>
      ) : (
        <>
          {user.user ? (
            <Space>
              <Popover content={<UserCard notify={notify} />} title={<></>}>
                <Button
                  type="text"
                  ghost
                  icon={
                    <Avatar size="small" src={<img src={`${user.user.avatar}`} alt="avatar" />} />
                  }
                >
                  {user.user?.name}
                </Button>
              </Popover>
              {user.user.role == UserRoles.ADMIN ? (
                <>
                  {" "}
                  <Link to={Paths.settings}>
                    <Button type="text" ghost icon={<SettingOutlined />}>
                      Настройки
                    </Button>
                  </Link>
                </>
              ) : (
                <></>
              )}
              <Button type="text" ghost icon={<LogoutOutlined />} onClick={onLogoutClick}>
                Выйти
              </Button>
            </Space>
          ) : (
            <Space>
              <Link to={Paths.register}>
                <Button type="text" ghost onClick={onRegister} icon={<UserOutlined />}>
                  Регистрация
                </Button>
              </Link>
              <Link to={Paths.login}>
                <Button type="text" ghost onClick={onLogin} icon={<LoginOutlined />}>
                  Войти
                </Button>
              </Link>
            </Space>
          )}
        </>
      )}
    </Layout.Header>
  );
};
