import { Drawer } from "antd";
import { LoginForm } from "../loginForm";
import { RegForm } from "../registerForm";

type Prop = {
  onCloseLogin: () => void;
  onOpenReg: () => void;
  onCloseReg: () => void;
  openLogin: boolean;
  openReg: boolean;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const AuthLayout = ({
  onOpenReg,
  onCloseLogin,
  onCloseReg,
  notify,
  openLogin,
  openReg,
}: Prop) => {
  return (
    <>
      <Drawer onClose={onCloseLogin} key="logindrawer" title="Авторизация" open={openLogin}>
        {openLogin ? <LoginForm onRegClick={onOpenReg} notify={notify} /> : <></>}
      </Drawer>
      <Drawer onClose={onCloseReg} key="registerdrawer" title="Регистрация" open={openReg}>
        {openReg ? <RegForm notify={notify} /> : <></>}
      </Drawer>
    </>
  );
};
