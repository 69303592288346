import { CheckOutlined, CloseOutlined, EditOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popover, Select, Space } from "antd";
import React, { useState } from "react";
import { MinecrServer } from "../../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { TRootReducer } from "../../../../../../app/redux/common/root";
import { editServerThunc } from "../../../../../../app/redux/common/reducers/servers/thuncs";
import useDeviceDetect from "../../../../../../app/hooks/isMobile";
import { GeneralPcSettingsMenu } from "./pc";
import { GeneralMobileSettingsMenu } from "./mobile";

type Props = {
  serverInfo: MinecrServer;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const GeneralSettingsMenu = ({ serverInfo, notify }: Props) => {
  const { isMobile } = useDeviceDetect();

  return (
    <>
      {isMobile ? (
        <>
          <GeneralMobileSettingsMenu serverInfo={serverInfo} notify={notify} />
        </>
      ) : (
        <>
          <GeneralPcSettingsMenu serverInfo={serverInfo} notify={notify} />
        </>
      )}
    </>
  );
};
