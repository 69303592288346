import React from "react";
import { MinecrServer } from "../../../../../../types";
import Dragger from "antd/es/upload/Dragger";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { Button, Popconfirm, UploadProps } from "antd";
import { useDispatch} from "react-redux";
import { message } from 'antd';
import { GlobalURL } from "../../../../../../app/api";
import { deleteFileThunc, getAllServersUserThunc } from "../../../../../../app/redux/common/reducers/servers/thuncs";

type Props = {
  serverInfo: MinecrServer;
  files: file_type[];
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
  folder: string;
};

type file_type = {
  file: string;
};




export const UploadServerFiles = ({ serverInfo, notify, folder, files }: Props) => {
  const dispatch = useDispatch();

  const deleteFileClick = (file: string) => {
    deleteFileThunc(serverInfo.id, folder, file, dispatch, notify);
  }

  const props: UploadProps = {
    name: 'files',
    multiple: true,
    action: `${GlobalURL}/api/servers/upload/${serverInfo.id}/${folder}`,
    headers: {
      'authorization': `Bearer ${localStorage.getItem('token')}`
  },
    onChange(info) {
      const { status } = info.file;
      if (status != 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} Успешно.`);
        getAllServersUserThunc(dispatch, notify);
      } else if (status === 'error') {
        message.error(`${info.file.name} ошибка загрузки.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  


  const columns: ColumnsType<file_type> = [
    {
      title: "Файл",
      dataIndex: "file",
      key: "file",

      render: (_, record) => <>{record.file}</>,
    },
    {
      title: "Действие",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <>
          <Popconfirm title="Удалить?" okText="Да" cancelText="Нет" onConfirm={(e) => {deleteFileClick(record.file)}}>
            <Button
              shape="round"
              danger={true}
              onClick={() => {}}
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ height: "30%" }}>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетяните файлы для загрузки!</p>
        </Dragger>
      </div>

      <Table
        pagination={{ pageSize: 5 }}
        style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
        dataSource={files}
        columns={columns}
      ></Table>
    </>
  );
};
