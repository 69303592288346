import { Button, Form, Space, Typography } from "antd";
import { FormInput } from "../inputs/formInput";
import { PasswordInput } from "../inputs/passwordInput";
import { loginThunc } from "../../app/redux/common/reducers/auth/thuncs";
import { useDispatch } from "react-redux";


type Prop<T> = {
  onRegClick: () => void;
  notify: {
    setSuccses: (msg: string) => void,
    setError: (msg: string) => void;
  }
  
}

export const LoginForm = ({notify, onRegClick}:Prop<string>) => {
  const dispatch = useDispatch();

  const login = async (data: any) => {
    await loginThunc(data, dispatch, notify);
    window.location.reload();
  };

  return (
    <>
      <Form onFinish={login} style={{ marginBottom: "10px" }}>
        <FormInput type="email" name="email" placeholder="Email" />
        <PasswordInput name="password" placeholder="Пароль"/>
        <Button key='loginBtn' type="primary" htmlType="submit" loading={false}>
          Войти
        </Button>
      </Form>
      <Space direction="vertical" size="large">
        <Typography.Text>
          Нет аккаунта? <Button ghost onClick={onRegClick} style={{color: 'yellow'}}>Зарегестрироваться</Button>
        </Typography.Text>
      </Space>
    </>
  );
};
