import React from "react";
import { PutiPage } from "../putiPage";
import { JavaVersionsTable } from "../javaVersionsTable";
import { AllowPropertiesTable } from "../allowPropertiesTable";
import { UsingPortsTable } from "../usingPortsTable";

type Props = {
  page: number;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const SettingsSwither = ({ page, notify }: Props) => {
  switch (page) {
    case 1:
      return <PutiPage notify={notify} />;
      break;
    case 2:
      return <JavaVersionsTable notify={notify} />;
    case 3:
        return <AllowPropertiesTable notify={notify} />
    case 4:
      return <UsingPortsTable notify={notify} />
    default:
      return <>пусто{`${page}`}</>;
      break;
  }
};
