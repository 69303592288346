import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TRootReducer } from "../../app/redux/common/root";
import { MinecraftServerCard } from "./components/minecraftServerCard";
import {  UserRoles } from "../../types";
import { Flex, FloatButton } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { AddServerModal } from "./components/addServerModal";
import styles from "./index.module.css";

type Props = {
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
  socket: any;
};

export const MinecraftServerPage = ({ socket, notify }: Props) => {
  const user_state = useSelector((state: TRootReducer) => state.user);
  const servers = useSelector((state: TRootReducer) => state.servers);
  const [addEnable, setAddEnable] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  const showAddModal = () => {
    setOpenAddModal(true);
  };

  const hideAddModal = () => {
    setOpenAddModal(false);
  };

  const setAddBtnEbabled = (value: boolean) => {
    setAddEnable(value);
  };

  useEffect(() => {
    if (user_state.user?.user_servers_limit)
      if (
        user_state.user?.user_servers_limit <= servers.servers.length &&
        user_state.user.role != UserRoles["ADMIN"]
      ) {
        setAddBtnEbabled(false);
      } else {
        setAddBtnEbabled(true);
      }
  }, [servers.servers]);

  return (
    <div className={styles.main}>
      <Flex vertical={false} className={styles.main}>
        {servers.servers.map((element) => (
          <MinecraftServerCard socket={socket} serverInfo={element} notify={notify} />
        ))}
      </Flex>
      {addEnable ? (
        <FloatButton
          icon={<PlusOutlined />}
          type="primary"
          style={{ width: 70, height: 70 }}
          description="Добавить сервер"
          onClick={showAddModal}
        />
      ) : (
        <FloatButton
          icon={<CloseOutlined />}
          type="default"
          style={{ width: 70, height: 70 }}
          description="Достигнут лимит"
        />
      )}
      <AddServerModal setOpenModal={setOpenAddModal} notify={notify} isOpenModal={openAddModal} />
    </div>
  );
};
