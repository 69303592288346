import { initialState } from "./initial";
import { ActionTypeMix, SET_SERVER, SET_SERVERS } from "./types";

export default (state = initialState, action: ActionTypeMix) => {
  switch (action.type) {
    case SET_SERVERS: {
      return { ...state, servers: action.value };
    }
    case SET_SERVER: {
      return { ...state.servers, servers: [ ...state.servers, action.value ] };
    }
    default:
      return state;
  }
};
