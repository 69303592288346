import { useState } from "react";
import { MinecrServer } from "../../../../types";
import { Layout, Modal } from "antd";
import { StatsMenu } from "./components/statsMenu";
import { StatsServerSwither } from "./components/statsSwitcher";
import styles from "./index.module.css";

type Props = {
  serverInfo: MinecrServer;
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
  socket: any;
  isOpen: boolean;
  status: string;
  hideThis: () => void;
  memoryUse: number;
};

export const FullStatsModal = ({
  memoryUse,
  serverInfo,
  status,
  notify,
  socket,
  isOpen,
  hideThis,
}: Props) => {
  const [menuPage, setMenuPage] = useState(1);
  return (
    <Modal title="Параметры" open={isOpen} onCancel={hideThis} footer="" width={1300}>
      <Layout className={styles.main}>
        <StatsMenu
          notify={notify}
          server={serverInfo}
          status={status}
          setMenuPage={setMenuPage}
          currentPage={menuPage}
        />
        <Layout.Content>
          <StatsServerSwither
            memoryUse={memoryUse}
            page={menuPage}
            status={status}
            serverInfo={serverInfo}
            socket={socket}
            notify={notify}
          />
        </Layout.Content>
      </Layout>
    </Modal>
  );
};

//<SettingsSwither page={menuPage} notify={notify} />
