import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TRootReducer } from "../../app/redux/common/root";
import { UserRoles } from "../../types";
import { Paths } from "../../paths";
import { Layout } from "antd";
import styles from "./index.module.css";
import { Loader } from "../loader";
import { SettingsMenu } from "./components/settingsMenu";
import { SettingsSwither } from "./components/settingsSwitcher";

type Props = {
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const SettingsPage = ({ notify }: Props) => {
  const navigate = useNavigate();
  const user_state = useSelector((state: TRootReducer) => state.user);
  const statuses = useSelector((state: TRootReducer) => state.status);
  const [menuPage, setMenuPage] = useState(1);

  useEffect(() => {
    if (user_state.user?.role != UserRoles.ADMIN) {
      navigate(Paths.home);
      return;
    }
  }, [statuses.settingsLoading]);

  return (
    <div className={styles.main}>
      {statuses.settingsLoading ? (
        <Loader />
      ) : (
        <div className={styles.main}>
          <Layout className={styles.main}>
            <SettingsMenu setMenuPage={setMenuPage} currentPage={menuPage}></SettingsMenu>
            <Layout.Content>
              <SettingsSwither page={menuPage} notify={notify} />
            </Layout.Content>
          </Layout>
        </div>
      )}
    </div>
  );
};
