import React from "react";
import { Button, Menu, Popconfirm } from "antd";
import { MinecrServer, ServerStatuses } from "../../../../../../../types";
import { closeProcessThunc } from "../../../../../../../app/redux/common/reducers/servers/thuncs";
import { useDispatch } from "react-redux";
import { CloseOutlined} from "@ant-design/icons";

type Props = {
    setMenuPage: (page: number) => void;
    currentPage: number;
    server: MinecrServer;
    status: string;
    notify: {
      setSuccses: (msg: string) => void;
      setError: (msg: string) => void;
    };
    mobile: boolean;
  };

export const FullStatsMenu = ({ setMenuPage, currentPage, notify, server, status, mobile }: Props) => {
    const dispatch = useDispatch();
  const setMenusPage = (event: any) => {
    setMenuPage(parseInt(event.key));
  };

  const close_process = () => {
    closeProcessThunc(server.id, dispatch, notify);
  };
  return (
    <>
      <Menu mode={mobile ? "horizontal": undefined} defaultSelectedKeys={[`${currentPage}`]} onSelect={setMenusPage}>
        <Menu.Item key={1}>Основные</Menu.Item>
        <Menu.Item key={2}>server.properties</Menu.Item>
        <Menu.Item key={3}>Управление миром</Menu.Item>
        {server.mods ? (
          <>
            <Menu.Item key={5}>Моды</Menu.Item>
          </>
        ) : (
          <></>
        )}
        {server.plugins ? (
          <>
            <Menu.Item key={6}>Плагины</Menu.Item>
          </>
        ) : (
          <></>
        )}
        <Menu.Item key={4}>Терминал</Menu.Item>
      </Menu>
      <Popconfirm
        title="Завершить?"
        description="Процесс принудительно завершится!"
        okText="Да"
        cancelText="Нет"
        disabled={parseInt(status) === ServerStatuses["Выключен"]}
        onConfirm={(e) => close_process()}
      >
        <Button
          danger={true}
          onClick={() => null}
          size="large"
          style={{ width: "100%" }}
          disabled={parseInt(status) === ServerStatuses["Выключен"]}
          icon={<CloseOutlined />}
        >
          Закрыть процесс
        </Button>
      </Popconfirm>
    </>
  );
};
