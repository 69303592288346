import { Button, Input, Layout, Popconfirm, Select, Table } from "antd";
import styles from "./index.module.css";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { TRootReducer } from "../../../../app/redux/common/root";
import { useDispatch, useSelector } from "react-redux";
import { AllowProperite } from "../../../../types";
import {
  addAllowProperiteThunc,
  editAllowProperiteThunc,
  removeAllowProperiteThunc,
} from "../../../../app/redux/common/reducers/settings/thuncs";
type Props = {
  notify: {
    setSuccses: (msg: string) => void;
    setError: (msg: string) => void;
  };
};

export const AllowPropertiesTable = ({ notify }: Props) => {
  const dispatch = useDispatch();
  const settings_state = useSelector((state: TRootReducer) => state.settings);

  const removeValue = (value: AllowProperite) => {
    removeAllowProperiteThunc(value.id, dispatch, notify);
  };

  const addValue = (value: AllowProperite) => {
    addAllowProperiteThunc({ id: 0, value: "", name: "", type: "string" }, dispatch, notify);
  };

  const editValue = (value: AllowProperite) => {
    editAllowProperiteThunc(value, dispatch, notify);
  };

  const columns: ColumnsType<AllowProperite> = [
    {
      title: "Значение",
      dataIndex: "path",
      key: "path",

      render: (_, record) => (
        <>
          <Input
            placeholder="Parameter"
            variant="borderless"
            defaultValue={record.value}
            disabled={record.id === -1}
            onBlur={(e) => {
              let tmp: AllowProperite = {
                ...record,
                value: e.target.value,
              };
              editValue(tmp);
            }}
          />
        </>
      ),
    },
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (_, record) => (
        <>
          <Input
            placeholder="Name"
            variant="borderless"
            defaultValue={record.name}
            disabled={record.id === -1}
            onBlur={(e) => {
              let tmp: AllowProperite = {
                ...record,
                name: e.target.value,
              };
              editValue(tmp);
            }}
          />
        </>
      ),
    },
    {
      title: "Тип поля",
      dataIndex: "type",
      key: "type",
      width: "12%",
      render: (_, record) => (
        <>
          {record.id == -1 ? (
            <>
              <Input
                placeholder="Type"
                variant="borderless"
                defaultValue={record.name}
                disabled={true}
              />
            </>
          ) : (
            <>
              <Select
                defaultValue={record.type}
                style={{ width: 120 }}
                onChange={(e) => {
                  let tmp: AllowProperite = {
                    ...record,
                    type: e,
                  };
                  editValue(tmp);
                }}
                options={[
                  { value: "string", label: "Строка" },
                  { value: "number", label: "Число" },
                  { value: "chbx", label: "Чекбокс" },
                ]}
              />
            </>
          )}
        </>
      ),
    },
    {
      title: "Действие",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <>
          {record.id === -1 ? (
            <>
              <Button
                shape="round"
                danger={false}
                onClick={() => addValue(record)}
                icon={<PlusOutlined />}
              ></Button>
            </>
          ) : (
            <Popconfirm
              title="Удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={(e) => removeValue(record)}
            >
              <Button
                shape="round"
                danger={true}
                onClick={() => null}
                icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Layout.Header className={styles.header}>
        Разрешенные для измнения в server.properties
      </Layout.Header>
      <Table
        style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
        dataSource={settings_state.allow_properite}
        columns={columns}
      ></Table>
    </>
  );
};
